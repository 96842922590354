const frasesDesenvolvimento = [
    "A simplicidade é a chave para a excelência no desenvolvimento de software.",
    "Aprender a programar é como aprender um novo idioma.",
    "O desenvolvimento de software é uma jornada de aprendizado contínuo.",
    "A colaboração é fundamental para o sucesso de um projeto de desenvolvimento.",
    "Código limpo é código feliz.",
    "A automação é o futuro do desenvolvimento de software.",
    "A qualidade é mais importante do que a quantidade de código produzido.",
    "A documentação é tão importante quanto o código.",
    "Teste seu código antes que os usuários o façam.",
    "A segurança deve ser uma prioridade no desenvolvimento de software.",
    "As melhores práticas estão em constante evolução.",
    "O versionamento do código é essencial para o trabalho em equipe.",
    "Mantenha-se atualizado com as tendências de desenvolvimento.",
    "Comentários de código devem explicar o 'porquê', não o 'o quê'.",
    "Os erros são oportunidades de aprendizado.",
    "Refatorar é parte do processo de desenvolvimento.",
    "Mantenha seus componentes pequenos e reutilizáveis.",
    "Evite a duplicação de código a todo custo.",
    "Aprenda com os desenvolvedores mais experientes.",
    "A resolução de problemas é uma habilidade crucial para desenvolvedores.",
    "Aprenda a escrever consultas SQL eficientes.",
    "A escalabilidade deve ser considerada desde o início.",
    "Acessibilidade é importante para alcançar todos os usuários.",
    "Use ferramentas de desenvolvimento para aumentar a produtividade.",
    "Testes automatizados são fundamentais para a estabilidade do software.",
    "Aprenda a depurar eficazmente.",
    "As melhores soluções são muitas vezes as mais simples.",
    "O sucesso de um projeto depende de uma boa gestão de tempo.",
    "Aceite feedback e esteja disposto a melhorar.",
    "Domine pelo menos uma linguagem de programação.",
    "Aprenda estruturas de dados e algoritmos.",
    "A experiência do usuário é tão importante quanto a funcionalidade.",
    "Mantenha-se calmo e concentre-se ao enfrentar problemas de código.",
    "A mentalidade de aprendizado é mais valiosa do que qualquer habilidade específica.",
    "Construa projetos pessoais para aprimorar suas habilidades.",
    "Aprenda a trabalhar bem em equipe.",
    "Conheça os princípios de design de interface do usuário.",
    "Aprenda a lidar com a frustração e o estresse.",
    "Use controle de versão para rastrear as mudanças em seu código.",
    "Aprenda a escrever código legível e autodocumentado.",
    "Mantenha-se curioso e sempre questione o status quo.",
    "Compartilhe seu conhecimento com a comunidade de desenvolvedores.",
    "A tecnologia está em constante evolução; esteja preparado para aprender constantemente.",
    "A empatia é importante ao desenvolver para um público diversificado.",
    "Aprenda a criar APIs RESTful eficazes.",
    "Evite a tentação de soluções rápidas e sujas.",
    "O código de produção não é o lugar para experimentos.",
    "Mantenha um equilíbrio saudável entre vida pessoal e trabalho.",
    "Aceite que você sempre terá mais a aprender.",
    "Aprenda a gerenciar seu tempo eficazmente.",
    "A automação pode economizar tempo e reduzir erros.",
    "A persistência é a chave para superar obstáculos no desenvolvimento.",
    "Nunca pare de explorar e experimentar novas tecnologias.",
    "A análise de dados pode revelar insights valiosos.",
    "Software e engenharia de software não são a mesma coisa.",
    "Programar é a arte de organizar e dominar a complexidade.",
    "Compartilhar é se importar. Programadores gostam de compartilhar.",
    "A criatividade é a parte mais importante da programação.",
    "A programação é a tradução do pensamento humano em algo que uma máquina pode entender.",
    "Os bugs são apenas oportunidades de aprendizado.",
    "A melhor documentação é o código em si.",
    "Cada programa de software é um esforço único e culturalmente significativo.",
    "Cada grande desenvolvedor que você conhece tem sido através de um período de escrever código terrível.",
    "O código de qualidade é o melhor plano de contingência.",
    "Só existe dois tipos de programadores: aqueles que sempre fazem backup e aqueles que ainda vão fazer backup.",
    "No mundo real, as pessoas processam dados, não máquinas.",
    "O código bem escrito é uma forma de arte.",
    "Desenvolver software é um esporte de equipe.",
    "Se o depurador te deixa depressivo, você se esqueceu de algo muito importante.",
    "Programar é como escrever um livro: você começa com um rascunho e, em seguida, revisa.",
    "Nada é impossível para um programador determinado.",
    "A programação é pensar em abstrações.",
    "A programação é a arte de criar algo do nada.",
    "Programadores são os construtores do século 21.",
    "A programação é o que torna a magia digital possível.",
    "Programar é transformar ideias em realidade.",
    "Programadores são os arquitetos do futuro.",
    "A programação é a magia por trás da tecnologia.",
    "Programar é a maneira de construir o futuro.",
    "A programação é a arte de criar o impossível.",
    "Programadores são os poetas da era digital.",
    "A programação é a chave para o mundo digital.",
    "Programar é como dar vida às máquinas.",
    "Programadores são os construtores da era digital.",
    "A programação é a arte de criar o impossível.",
    "Programadores são os poetas do século 21.",
    "A programação é a magia do nosso tempo.",
    "Programar é a arte de criar possibilidades infinitas.",
    "A programação é a base da era digital.",
    "Programar é a maneira de moldar o futuro.",
    "A programação é a ponte para o desconhecido.",
    "Programadores são os exploradores da era digital.",
    "A programação é a ferramenta para criar o inimaginável.",
    "Programar é a arte de criar o virtual.",
    "A programação é a linguagem da revolução digital.",
    "Programar é a maneira de construir o futuro.",
    "A programação é a arte de criar o digital.",
    "Programadores são os visionários da era digital.",
    "A programação é a linguagem da inovação.",
    "Programar é a arte de criar o possível.",
    "A programação é a chave para o mundo digital.",
    "Programadores são os arquitetos do século 21.",
    "A programação é a magia por trás da tecnologia.",
    "Programar é a maneira de criar o impossível.",
    "A programação é a linguagem do futuro.",
  ];
  
  export default frasesDesenvolvimento;
