import React from 'react';
import frasesDesenvolvimento from './frasesDesenvolvimento'; // Importe as frases

function MeuComponente() {
  // Gere um índice aleatório para escolher uma frase
  const indiceAleatorio = Math.floor(Math.random() * frasesDesenvolvimento.length);

  // Exiba a frase aleatória
  const fraseAleatoria = frasesDesenvolvimento[indiceAleatorio];

  return (
    <div className="frase-container">
      <p className="frase">{fraseAleatoria}</p>
    </div>
  );
}

export default MeuComponente;
